import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Theme from "../components/Theme"
import GlobalStyle from "../components/GlobalStyle"
import Header from "../components/header"
import styled from "styled-components"
import BigSection from "../components/BigSection"

const NotFoundPage = () => (
  <Theme>
    <GlobalStyle />
    <Layout>
      <SEO title="404: Not found" />
      <Header />
      <FullBleed>
        <BigSection>
          <h3>Oops!</h3>
          <p>
            That page doesn't exist. You can go back to the home page{" "}
            <Link to="/">here</Link>.
          </p>
        </BigSection>
      </FullBleed>
    </Layout>
  </Theme>
)

export default NotFoundPage

const FullBleed = styled.div`
  height: 100vh;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  align-items: center;
  a {
    text-decoration: underline;
  }
  p {
    max-width: 450px;
  }
`
