import React from "react"
import styled from "styled-components"

const BigSection = styled.section`
  margin: 70px 0;
  .text-container {
    max-width: 700px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
    padding: 50px 0;
  }
  .image-container {
    margin: 0 auto;
    max-width: 1000px;
    padding: 60px 0 0 0;
  }
  @media (max-width: 600px) {
    .text-container {
      width: 100%;
      padding: 0;
    }
  }
`

export default BigSection
